.wpo-section-title,
.wpo-section-title-s2,
.wpo-section-title-s3 {
  margin-bottom: 60px;
  text-align: center;

  @include media-query(767px) {
    margin-bottom: 40px;
  }

  span {
    text-transform: uppercase;
    font-size: 16px;
    color: $theme-primary-color;
    font-family: $base-font;
    display: block;
    letter-spacing: 0.2em;

    @include media-query(575px) {
      font-size: 15px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 50px;
    line-height: 170.9%;
    margin: 0;
    position: relative;
    font-family: $heading-font;
    color: $dark-gray;
    display: inline-block;
    position: relative;

    @include media-query(767px) {
      font-size: 28px;
      line-height: 55px;
    }
  }

  p {
    font-size: 18px;
  }
}

.wpo-section-title-s2 {
  padding-top: 100px;
  position: relative;
  z-index: 1;
  @media (max-width: 1200px) {
    padding-top: 80px;
  }
  @media (max-width: 991px) {
    padding-top: 60px;
  }

  .invisible-text {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 100%;

    h2 {
      font-weight: 900;
      font-size: 200px;
      color: rgba(202, 171, 6, 0.2);
      line-height: 120px;

      @media (max-width: 1200px) {
        font-size: 150px;
      }

      @media (max-width: 991px) {
        font-size: 100px;
      }
      @media (max-width: 575px) {
        font-size: 70px;
        line-height: 100px;
      }
    }
  }
}

// wpo-section-title-s3

.wpo-section-title-s3 {
  h2 {
    color: $white;
  }
}
