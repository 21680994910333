.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure the modal is on top */
}

.modal-content {
  border-radius: 8px;
  overflow: auto;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  margin: auto;
}

.slick-dots li button:before {
  font-size: 15px;
}
